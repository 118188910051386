import React from "react";
import AppLayout from "../layout/AppLayout";
import ContactForm from "../components/forms/ContactForm";

const contact = () => {
  return (
    <AppLayout
      routes={[
        { name: "Data Center Physical Solutions", href: "/#physical-data" },
        { name: "Technology Services", href: "/#technology" },
        { name: "Data Center Consulting", href: "/#data-center" },
        { name: "Contact Us", href: "#" },
      ]}
    >
      <ContactForm />
    </AppLayout>
  );
};

export default contact;
