import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import pagebg from "../../images/pagebg.png";

const Container = ({ className }) => {
  // debugger;
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
  });
  const setFormField = (field, value) => {
    setForm((form) => ({ ...form, [field]: value }));
  };
  const sendForm = () => {
    axios
      .post("https://unblogged.herokuapp.com/email", { form })
      .then((res) => {
        console.log(res);
        setForm(() => ({
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          message: "",
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={`pt-1 sm:pt-8 mx-auto ${className}`}>
      <div
        className="flex items-center justify-center h-64 mb-12 text-5xl font-bold text-white uppercase"
        style={{ backgroundImage: `url(${pagebg})` }}
      >
        Contact Us
      </div>
      <div className="flex flex-col items-center">
        <div className="w-11/12 p-2 border border-black rounded-lg sm:w-582px">
          <div className="flex flex-wrap justify-between">
            <div className="w-full sm:w-48% pt-5">
              <label className="block font-bold" htmlFor="first">
                First Name:
              </label>
              <input
                name="first"
                type="text"
                className="w-full px-2 py-2 bg-gray-100 rounded-lg"
                value={form.firstName}
                onChange={(event) =>
                  setFormField("firstName", event.target.value)
                }
                placeholder="First name here"
              />
            </div>
            <div className="w-full sm:w-48% pt-5">
              <label className="block font-bold" htmlFor="last">
                Last Name:
              </label>
              <input
                name="last"
                type="text"
                className="w-full px-2 py-2 bg-gray-100 rounded-lg"
                value={form.lastName}
                onChange={(event) =>
                  setFormField("lastName", event.target.value)
                }
                placeholder="Last name here"
              />
            </div>
            <div className="w-full pt-5">
              <label className="block font-bold" htmlFor="email">
                Email:
              </label>
              <input
                name="email"
                type="email"
                className="w-full px-2 py-2 bg-gray-100 rounded-lg"
                value={form.email}
                onChange={(event) => setFormField("email", event.target.value)}
                placeholder="Write your email"
              />
            </div>
            <div className="w-full pt-5">
              <label className="block font-bold" htmlFor="company">
                Company:
              </label>
              <input
                name="company"
                type="text"
                className="w-full px-2 py-2 bg-gray-100 rounded-lg"
                value={form.company}
                onChange={(event) =>
                  setFormField("company", event.target.value)
                }
                placeholder="Write your company"
              />
            </div>
            <div className="w-full pt-5">
              <label className="block font-bold" htmlFor="message">
                Message:
              </label>
              <textarea
                name="message"
                type="text"
                className="w-full px-2 py-2 bg-gray-100 rounded-lg"
                value={form.message}
                onChange={(event) =>
                  setFormField("message", event.target.value)
                }
                placeholder="Write your message"
                rows={5}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            className="p-2 m-3 text-xl duration-100 border border-black rounded-lg hover:bg-black hover:text-white"
            onClick={sendForm}
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
};
Container.defaultProps = {
  className: "",
};

export default Container;
